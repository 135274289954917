import React from 'react';
import './Home.css';
import { Helmet } from 'react-helmet';
const Home = () => {
  return (
    <div className='H'>
      <Helmet>
      <title>Home - RC Packages</title>
        <meta
          name="description"
          content="Welcome to RC Packages - Your Trusted Partner in Corrugated Box Solutions. At RC Packages, we specialize in delivering high-quality corrugated box solutions tailored to meet your unique packaging needs. With years of industry experience and a commitment to excellence, we take pride in being your trusted partner for all your packaging requirements."
        />
        <meta
          name="keywords"
          content="RC Packages,packages, packaging solutions, sustainable packaging, paperboard folding carton, 24/7 manufacturing, recyclable products, quality control"
        />
      </Helmet>
      <div className='vid'>
      <video autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover'}} >
        <source src="/vid.mp4" type="video/mp4" />
        {/* You can add additional source elements for different video formats */}
        Your browser does not support the video tag.
      </video>
      </div>
      <div className="home-container">
        <h1 className="home-heading">Welcome to RC Packages - Your Trusted Partner in Corrugated Box Solutions</h1>
        <p className="home-text">At RC Packages, we specialize in delivering high-quality corrugated box solutions tailored to meet your unique packaging needs. With years of industry experience and a commitment to excellence, we take pride in being your trusted partner for all your packaging requirements.</p>
      </div>
      <div className="centered-div">
      <p>
        "Quality is an assurance that only the confident and calm can provide.
        Confident in our own capabilities, our trained staff are dedicated to
        the ideal of Quality. Every product undergoes stringent testing and our
        experienced staff are always at hand to prevent lower standards. Our
        commitment to quality is our motivation to endeavor and reach out to
        creating great products in record time." - RC Packages
      </p>
      <img src="/r2.png" alt="Image description" className="right-image" />
    </div>
    </div>
  );
}

export default Home;
