import React from 'react';
import './About.css';
import { Helmet } from 'react-helmet';


const About = () => {
  return (
    
    <div className="about-container">
      <Helmet>
      <title>About Us - RC Packages</title>
        <meta
          name="description"
          content="Learn about RC Packages - your reliable partner for innovative, cost-effective, and sustainable packaging solutions."
        />
        <meta
          name="keywords"
          content="RC Packages,packages, packaging solutions, sustainable packaging, paperboard folding carton, 24/7 manufacturing, recyclable products, quality control"
        />
      </Helmet>
      <h1 className="about-heading">About Us</h1>
      <div className="about-text">
        <p>
          At RC Packages, we understand the importance of reliable packaging solutions in today's competitive market. That's why we strive to provide innovative, cost-effective, and sustainable packaging solutions to businesses of all sizes. Our state-of-the-art manufacturing facilities and skilled team ensure that every box we produce meets the highest standards of quality and durability. RC packages is one of the country's famous privately owned paperboard folding carton manufacturers. RC packages stand ready to assist companies of all sizes with their packaging needs. We proudly create unrivaled service.
        </p>
        <p>
          RC staff has complete technical knowledge about the corrugated box machinery, having worked with automated plants. Their combined experience and sheer unbridled enthusiasm along with stringent Quality Control standards has been instrumental in ensuring exponential growth. Equipped to manufacture 24X7, the firm is an accredited member of the LCCI.
        </p>
        <p>
          We provide solutions that create brand value and make room for business improvement. Our main focus is on maximizing the business value of packaging for our customers.
        </p>
      </div>
      <div className="about-advantage">
        <h2>The RC Package Advantage</h2>
        <ul>
          <li>24/7 Manufacturing</li>
          <li>Recyclable Products</li>
          <li>Quality Control</li>
          <li>100 Tonnes per month</li>
          <li>In House Printing</li>
          <li>In House corrugation</li>
        </ul>
      </div>
      
    </div>
    
  );
}

export default About;
