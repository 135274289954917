import React from 'react';
import './Product.css';

const Product = () => {
  return (
    <div className="product-page">
      <h1 className="page-title">Products</h1>
      <p className="page-description">
        Our 24 X 7 manufacturing facility ensures delivery on schedule, and we are fully equipped to manufacture and deliver various types of corrugated boxes as per the client's requirements. We also endeavor to provide total packaging solutions including Print, Design layouts and packing equipment in tandem with our creative team. Some of the products we specialize in are:
      </p>
      <div className="product-list">
        <div className="product-item">
          <h2>Master Cartons</h2>
          <p>High-quality master cartons for all your packaging needs.</p>
        </div>
        <div className="product-item">
          <h2>Mono Cartons</h2>
          <p>Single-layer cartons designed for specific packaging requirements.</p>
        </div>
        <div className="product-item">
          <h2>Corrugated Sheets</h2>
          <p>Strong and durable corrugated sheets for various applications.</p>
        </div>
        <div className="product-item">
          <h2>Corrugated Rolls</h2>
          <p>Long rolls of corrugated material suitable for custom packaging solutions.</p>
        </div>
        <div className="product-item">
          <h2>Shipping Boxes</h2>
          <p>Sturdy shipping Boxes for safe transportation of goods.</p>
        </div>
        <div className="product-item">
          <h2>Die Cut Items</h2>
          <p>Precision die-cut items according to your specifications.</p>
        </div>
        <div className="product-item">
          <h2>Partitions</h2>
          <p>Partition inserts to organize and protect your products during transit.</p>
        </div>
        <div className="product-item">
          <h2>Tape</h2>
          <p>Durable adhesive tapes for sealing your packages securely.</p>
        </div>
        <div className="product-item">
          <h2>Taper Machine</h2>
          <p>Efficient taper machines for sealing cartons with ease.</p>
        </div>
      </div>
    </div>
  );
};

export default Product;
