import React from 'react';
import './Contact.css';
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <div className="contact-container">
      <Helmet>
      <title>Contact Us - RC Packages</title>
        <meta
          name="description"
          content="Contact RC Packages - your reliable partner for innovative, cost-effective, and sustainable packaging solutions."
        />
        <meta
          name="keywords"
          content="RC Packages,packages, packaging solutions, sustainable packaging, paperboard folding carton, 24/7 manufacturing, recyclable products, quality control"
        />
      </Helmet>
      <h2>Contact Us</h2>
      <div className="address">
        <p>RC Packages</p>
        <p>Allah Hoo Industrial Estate, 22KM off Ferozpur Road, Lahore, Pakistan</p>
      </div>
      <div className="contact-info">
        <p>Phone: <a href="tel:+924235965233">+92-42-3-596-5233</a> </p>
        <p>Cell Phone: <a href="tel:+923234813333">+92-323-4813333</a> / <a href="tel:+923233433000">+92-323-3433000</a></p>
        <p>Whatsapp: <a href="https://wa.me/923234813333">Just Click Here - +92-323-4813333</a></p>
        <p>Email: <a href="mailto:info@rcpackages.com">info@rcpackages.com</a></p>
        <p>Email: <a href="mailto:rcpackages@yahoo.com">rcpackages@yahoo.com</a></p>
      </div>
      <p>Contact us for any packaging needs you have - from delicate fragile products to large and heavy goods. You can visit us at our Factory premises or call us on <a href="tel:+923234813333">+92-323-4813333</a> and we will get in touch. In case you have any queries or request a quote just mail/Whatsapp us and we will customize our response according to your requirement.</p>
      <div className="map-container">
        {/* Paste the embedded map HTML code here */}
        {/* Example: <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.6102354936156!2d-0.07694448519839865!3d51.50973656420782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604e4dd8e91a1%3A0x2b8003e8e0b924b5!2sBig%20Ben!5e0!3m2!1sen!2suk!4v1644903792203!5m2!1sen!2suk" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3406.030338698775!2d74.34676547465202!3d31.38572697427589!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919077d7b78f083%3A0x243c89211e6e3a04!2sRC%20Packages!5e0!3m2!1sen!2s!4v1708933000703!5m2!1sen!2s" width="100%" height="350" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  );
}

export default Contact;
